import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormControl from 'components/Core/FormControl';
import RadioButton from 'components/Core/RadioButton';
import Button from 'components/Core/Button';
import { getFormValid } from 'utils/formUtils';
import { GraderFormContainer, FormStep } from '../styles';
import { FormContainer } from '../../styles';

const GraderForm2 = ({
  width,
  height,
  background,
  titleColor,
  buttonTitle,
  footer,
  shadow,
  marketoId,
  marketoURL,
  marketoFormId,
  stepOneValues,
  ...otherProps
}) => {
  const [formSubmissions, setFormSubmissions] = useState(true);

  const formik = useFormik({
    initialValues: {
      scheduling: null,
      dispatch: null,
      tracking: null,
      estimates: null,
    },
    validationSchema: Yup.object().shape({
      scheduling: Yup.string().required('Scheduling is required'),
      dispatch: Yup.string().required('Dispatch is required'),
      tracking: Yup.string().required('Tracking is required'),
      estimates: Yup.string().required('Estimate is required'),
    }),
    onSubmit: (values) => {
      onSubmitStepTwo();
    },
  });

  const onHandleChange = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(id, newValue);
  };

  const onSubmitStepTwo = (e) => {
    const values = { ...stepOneValues, ...formik.values };
    const serviceTitanPercentageIncreaseHero = Math.floor(
      (+values.scheduling + +values.dispatch + +values.tracking + +values.estimates) * 100,
    );
    navigate('/tools/service-business-grader-results', {
      state: { values, serviceTitanPercentageIncreaseHero },
    });
    setFormSubmissions(!formSubmissions);
  };

  const formValid = getFormValid({ ...formik });

  return (
    <FormContainer {...otherProps}>
      <div className="section-tool-page-form--wrapper">
        <div className="section-tool-page-form--container">
          <GraderFormContainer
            width={width}
            height={height}
            background={background}
            titleColor={titleColor}
            shadow={shadow}
            {...otherProps}
          >
            <p className="signup-form--description">* All fields required</p>
            <div className="signup-form--form" data-position="business grader step 2">
              <FormStep>
                <FormControl fullWidth>
                  <RadioButton
                    id="scheduling"
                    label="How do you handle scheduling?"
                    options={[
                      { value: 0.08, label: 'Manually, using paper or a whiteboard' },
                      { value: 0.08, label: 'Google/Outlook or another digital calendar' },
                      { value: 0, label: 'Field management software' },
                    ]}
                    values={formik.values}
                    formValid={formValid}
                    handleChange={onHandleChange}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <RadioButton
                    id="dispatch"
                    label="How do you dispatch your techs?"
                    options={[
                      { value: 0.07, label: 'I call or text them' },
                      { value: 0.07, label: 'A shared digital calendar' },
                      { value: 0, label: 'Field management software' },
                    ]}
                    values={formik.values}
                    formValid={formValid}
                    handleChange={onHandleChange}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <RadioButton
                    id="tracking"
                    label="How do you alert your customers when your techs are on their way?"
                    options={[
                      { value: 0.07, label: "I don't!" },
                      { value: 0.07, label: 'I give them a standard arrival window' },
                      { value: 0, label: 'Real-time GPS tracking' },
                    ]}
                    values={formik.values}
                    formValid={formValid}
                    handleChange={onHandleChange}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <RadioButton
                    id="estimates"
                    label="How do your employees upsell a customer?"
                    options={[
                      { value: 0.08, label: "They don't/rarely" },
                      { value: 0.08, label: 'Verbally or via written estimate' },
                      { value: 0.08, label: 'During a callback (or happy call)' },
                    ]}
                    values={formik.values}
                    formValid={formValid}
                    handleChange={onHandleChange}
                  />
                </FormControl>
                <FormControl fullWidth data-position="Form - Submit CTA">
                  <Button
                    className="signup-form--submit-button"
                    type="primary"
                    width="100%"
                    shadow={false}
                    style={{ marginTop: 15 }}
                    onClick={() => formik.submitForm()}
                  >
                    Submit
                  </Button>
                </FormControl>
                <div className="form-progress">
                  <div className="form-step complete"></div>
                  <div className="form-step active"></div>
                  <div className="form-step"></div>
                </div>
              </FormStep>
            </div>
          </GraderFormContainer>
        </div>
      </div>
    </FormContainer>
  );
};

GraderForm2.defaultProps = {
  background: 'var(--white)',
  buttonTitle: 'Next',
  footer: '',
  shadow: true,
};

export const BCGraderForm2 = GraderForm2;
