import styled from 'styled-components';

export const RadioButtonWrapper = styled.div`
  .radioButtonTitle {
    color: var(--darkest);
    font-size: var(--font-size-20);
    font-weight: var(--font-weight-700);
    letter-spacing: var(--letter-spacing--0-01);
    line-height: var(--line-height-120);
    margin-bottom: 16px;
  }
  .radioButtonTitle.input-failed-value {
    color: var(--critical-3);
  }
  .radioButtonContainer {
    display: block;
    position: relative;
    padding-left: 50px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    color: var(--dark);
    font-size: var(--font-size-18);
    margin-top: 10px;
  }
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .radioButton {
    position: absolute;
    top: 4px;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: var(--white);
    border: 2px solid var(--grey-4);
    border-radius: 50%;
    transition: all 200ms ease;
  }
  .radioButtonContainer:hover input ~ .radioButton {
    border: 2px solid var(--titan-blue-3);
  }
  .radioButtonContainer input:checked ~ .radioButton {
    background-color: var(--white);
    border: 2px solid var(--titan-blue-3);
  }
  .radioButton:after {
    content: '';
    position: absolute;
    display: none;
  }
  .radioButtonContainer input:checked ~ .radioButton:after {
    display: block;
  }
  .radioButtonContainer .radioButton:after {
    top: 5px;
    left: 5px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: var(--titan-blue-3);
  }
`;
