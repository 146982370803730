import React from 'react';
import styled from 'styled-components';
import { WithFormControl } from '../FormControl';

import { RadioButtonWrapper } from './styled.js';

const RadioButton = (props) => {
  const {
    id,
    label,
    values,
    formValid,
    formErrors,
    handleFocus: onFocus,
    handleChange: onChange,
    ...rest
  } = props;

  const handleFocus = (event) => {
    if (onFocus !== undefined) onFocus(id);
  };

  const handleChange = (event) => {
    onChange(id, event.target.value);
  };

  return (
    <>
      <RadioButtonWrapper formValid={formValid} id={id}>
        <span className={`radioButtonTitle${formValid[id] === -1 ? ' input-failed-value' : ''}`}>
          {props.label}
        </span>
        {props.options.map((item, index) => {
          return (
            <label
              htmlFor={props.id + '_' + index}
              onClick={handleFocus}
              className="radioButtonContainer"
            >
              {item.label}
              <input
                name={props.id}
                id={props.id + '_' + index}
                type="radio"
                value={item.value}
                onChange={handleChange}
              />
              <span className="radioButton"></span>
            </label>
          );
        })}
      </RadioButtonWrapper>
    </>
  );
};

export default WithFormControl(RadioButton);
