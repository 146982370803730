import React from 'react';
import { graphql } from 'gatsby';
import ToolboxLayout from 'layouts/toolboxLayout';
import Hero from 'components/Tools/Hero';
import { BCGraderForm2 } from 'components/Tools/BusinessGrader/Form2';
import STTestimonialCarousel from 'components/Sections/TestimonialCarousel/STTestimonialCarousel';

const GraderCalculatorPage2 = (props) => {
  const data = props.data.contentfulTool;
  const photoImg = props.data.contentfulImage;

  const stepOneValues = props.location.state && props.location.state.values;

  const allFeedbacks = [
    {
      title:
        '“ServiceTitan grew Bryant Electric’s revenue by 109%. It’s changed our business and it’s changed our life”',
      description: {
        description: '',
      },
      photo: photoImg.image,
      clientName: 'Jeff Bryant of Bryant Electric',
      companyName: 'ServiceTitan customer for 3 years',
    },
  ];

  data.seo.pageUrl += '/2';

  const subheadContent = JSON.parse(data.hero.subhead.raw);
  if (subheadContent?.content[0]?.content[0]?.value) {
    subheadContent.content[0].content[0].value =
      "You're almost done! Only a few more questions left...";
    data.hero.subhead = {
      ...data.hero.subhead,
      raw: JSON.stringify(subheadContent),
    };
  }

  return (
    <ToolboxLayout contentfulSeo={data.seo} layout="toolbox" {...props}>
      <Hero data={data.hero} />
      <BCGraderForm2 Id={2247} width="100%" stepOneValues={stepOneValues} />
      <STTestimonialCarousel
        type="descriptiononly"
        background="var(--skyline-1)"
        feedbacks={allFeedbacks}
      />
    </ToolboxLayout>
  );
};

export default GraderCalculatorPage2;

export const GraderCalculatorPage2Query = graphql`
  query GraderCalculatorPage2Query {
    contentfulTool(internalName: { eq: "Service Business Grader" }) {
      ...Tool
    }
    contentfulImage(contentful_id: { eq: "1K4u6yyVbtkWXnOoSVfc5Q" }) {
      internalName
      image {
        file {
          url
        }
      }
    }
  }
`;
